import React, { Component } from 'react'
import Marquee from 'react-fast-marquee'

import logo1 from '../assets/images/brand/cards/1.webp'
import logo2 from '../assets/images/brand/cards/2.webp'
import logo3 from '../assets/images/brand/cards/3.webp'
import logo4 from '../assets/images/brand/cards/4.webp'
import logo5 from '../assets/images/brand/cards/5.webp'
import logo6 from '../assets/images/brand/cards/6.webp'
import logo7 from '../assets/images/brand/cards/7.webp'
import logo8 from '../assets/images/brand/cards/8.webp'
import logo9 from '../assets/images/brand/cards/9.webp'
import logo10 from '../assets/images/brand/cards/10.webp'
import logo11 from '../assets/images/brand/cards/11.webp'
import logo12 from '../assets/images/brand/cards/12.webp'
import logo13 from '../assets/images/brand/cards/13.webp'
import logo14 from '../assets/images/brand/cards/14.webp'
import logo15 from '../assets/images/brand/cards/15.webp'
import logo16 from '../assets/images/brand/cards/16.webp'
import logo17 from '../assets/images/brand/cards/17.webp'
import logo18 from '../assets/images/brand/cards/18.webp'

class BrandTwo extends Component {
  render() {
    const images = [
      logo1,
      // logo2,
      logo3,
      // logo4,
      // logo5,
      logo6,
      logo7,
      logo8,
      logo9,
      logo10,
      logo11,
      logo12,
      logo13,
      logo14,
      logo15,
      logo16,
      logo17,
      logo18,
    ]

    return (
      <Marquee speed={130}>
        <div className="p-2">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Logo Image ${index}`}
              style={{
                margin: '0px 40px',
                height: '230px',
                width: '230px',
                transform: index % 2 == 0 ? 'rotate(-4deg)' : 'rotate(4deg)',
                borderRadius: '10px',
                userSelect: 'none',
              }}
            />
          ))}
        </div>
      </Marquee>
    )
  }
}
export default BrandTwo
