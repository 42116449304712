import React, { Component } from 'react'

import { FiMonitor } from 'react-icons/fi'
import { TbDeviceMobileCode } from 'react-icons/tb'
import { PiFigmaLogoThin, PiAppStoreLogoLight } from 'react-icons/pi'
import { VscRobot } from 'react-icons/vsc'
import { PiOpenAiLogoThin } from 'react-icons/pi'

const ServiceList = [
  {
    icon: <TbDeviceMobileCode />,
    title: 'MOBILE APP DEVELOPMENT',
    description:
      'In a world where everyone has an app, we make yours stand out. Be it aesthetic UIs or robust backends, our mobile app development services have you covered.',
  },
  {
    icon: <FiMonitor />,
    title: 'WEB DEVELOPMENT',
    description:
      'Anybody can build you a website. We build for scale. Our web development services future-proof your product from Day 1.',
  },
  {
    icon: <PiOpenAiLogoThin />,
    title: 'GENERATIVE AI',
    description:
      "Having trouble keeping up with how fast AI is changing our world? This is where we come in. Let's figure out how to get GenAI to work for you.",
  },
  // {
  //   icon: <PiFigmaLogoThin />,
  //   title: 'UI/UX Design',
  //   description:
  //     'Design isn’t just how it looks, it’s how it works. We craft intuitive interfaces that feel natural to users, ensuring your product not only looks good but is a pleasure to use.',
  // 'Our in-house designers studies the needs, create user personas, and design platforms for the best user experience. We ensure your digital products are intuitive, engaging, and tailored to your audience.',
  // },
]

class ServiceTwo extends Component {
  render() {
    let title = 'Our Services',
      description =
        'We’ve partnered with top companies worldwide to help them build their ideal digital products with our exceptional services.'

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
              <div className="service-btn">
                <a
                  className="btn-transparent rn-btn-dark"
                  // href="/service"
                >
                  <span className="text">Request Custom Service</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <a
                  // href="/service-details"
                  >
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default ServiceTwo
