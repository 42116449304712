import React, { Component, Fragment } from 'react'
import ScrollToTop from 'react-scroll-up'
import { FiChevronUp, FiCheck } from 'react-icons/fi'
import Header from '../component/header/Header'
import Footer from '../component/footer/Footer'

import SliderOne from '../component/slider/SliderOne'
import ServiceTwo from '../elements/service/ServiceTwo'
import CounterTwo from '../elements/counters/CounterTwo'
import Testimonial from '../elements/Testimonial'
import About from '../component/HomeLayout/homeOne/About'
import Portfolio from '../component/HomeLayout/homeOne/Portfolio'
import BlogContent from '../elements/blog/BlogContent'
import BrandTwo from '../elements/BrandTwo'
import Helmet from '../component/common/Helmet'
import CallAction from '../elements/callaction/CallAction'
import FooterTwo from '../component/footer/FooterTwo'
import TabOne from '../elements/tab/TabOne'

import about from '../assets/images/about/landing_about.jpg'

let title = 'About',
  description =
    "INFILP is a team of like-minded highly specialized people; with the skills and the passion to push the boundaries of software architecture and development. Whether you're looking to create a consumer-facing app, an enterprise solution, or anything in between, INFILP has the expertise and passion to bring your ideas to life.",
  namesItemOne = [
    'The Philosophy Of business analytics',
    'Fast-Track Your business',
    'Lies And Damn Lies About business',
    'The Ultimate Deal On business',
  ]

class MainDemo extends Component {
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="INFILP" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Slider Area   */}
        {/* <div className="slider-wrapper"> */}
        <SliderOne />
        {/* </div> */}
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src={about} alt="About Images" />
                  </div>
                </div>

                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title" style={{ marginTop: 0 }}>
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <TabOne tabStyle="tab-style--1" />
                    </div>

                    {/* <div className="mt--30">
                      <h4>Lorem ipsum dolor sit.</h4>
                      <ul className="list-style--1">
                        {namesItemOne.map((name, index) => {
                          return (
                            <li key={index}>
                              <FiCheck /> {name}
                            </li>
                          )
                        })}
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start CounterUp Area */}
        {/* <div className="rn-counterup-area pt--110 pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterTwo />
          </div>
        </div> */}
        {/* End CounterUp Area */}

        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>

        {/* Start Portfolio Area */}
        <div className="portfolio-area pt--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <Portfolio />
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation">
          <BrandTwo />
        </div>
        {/* End Brand Area */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <Testimonial />
        </div>
        {/* End Testimonial Area */}

        {/* Start Blog Area */}
        {/* <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="section-title text-left">
                  <h2>Latest News</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                  <a className="btn-transparent rn-btn-dark" href="/blog">
                    <span className="text">View All News</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img
                          className="w-100"
                          src={value.images}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* End Blog Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* <Footer /> */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
      </Fragment>
    )
  }
}
export default MainDemo
