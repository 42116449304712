import React, { Component } from 'react'

import aboutImg from '../../../assets/images/about/landing_about.jpg'

class About extends Component {
  render() {
    let title = 'About',
      description =
        'INFILP is a premier mobile and web development agency that excels in delivering innovative and high-quality digital solutions. Our name stands for ingenuity, flexibility, and precision, reflecting our commitment to creating custom software and applications that drive success for businesses of all sizes. At INFILP, we blend cutting-edge technology with creative expertise to turn your ideas into powerful digital experiences.'

    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src={aboutImg} alt="About Images" />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Why choose us?</h3>
                        <p>
                          We understand that many can offer similar services,
                          but our commitment to absolute reliability makes us
                          the best choice for your project.
                        </p>
                      </div>
                    </div>

                    {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default About
