import React, { Component } from "react"

import icon1 from "../../assets/images/icons/icon-01.png"
import icon2 from "../../assets/images/icons/icon-02.png"
import icon3 from "../../assets/images/icons/icon-03.png"

import { FiMonitor } from "react-icons/fi"
import { TbDeviceMobileCode } from "react-icons/tb"
import { PiFigmaLogoThin, PiAppStoreLogoLight } from "react-icons/pi"

const ServiceList = [
  {
    icon: <TbDeviceMobileCode />,
    title: "Mobile App Development",
    description:
      "We build mobile apps that digitally transform your business, fulfill fanatical high standards, and top perform in the market.",
  },
  {
    icon: <FiMonitor />,
    title: "Website Development",
    description:
      "Be ready to experience high conversion by developing an industry-leading website by partnering with us.",
  },
  {
    icon: <PiFigmaLogoThin />,
    title: "UI/UX Design",
    description:
      "From our designs can еxpеct nothing short of rеvolutionizing thе way usеrs interact with your digital products.",
  },
]

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">{val.icon}</div>

                {/* <div className="icon">
                  <img src={val.icon} alt="Digital Agency" />
                </div> */}
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}
export default ServiceOne
