import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const PortfolioList = [
  {
    id: 'the-green-felts',
    mockupLink:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2Fmockup.webp?alt=media&token=7bf26377-aba6-4366-9375-1b70afd0f7d8',
    title: 'The Green Felts',
    description:
      'This mobile app simplifies event creation and participant management for golf formats like MatchPlay, Stroke Play, and Stableford. With real-time scoring, automatic winner calculations, live leaderboards, and league management, it’s a must-have for smooth and engaging golf club operations.',
    buttonColor: 'rgba(0,128,128,1)',
    bgColor: 'rgba(0,128,128,0.1)',
    link: '/portfolio/the-green-felts',
  },
  {
    id: 'easy-as-pie-budget',
    title: 'Easy As Pie Budget',
    mockupLink:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2FPortfolio%20Card%20Templates%20(1).webp?alt=media&token=66fecc5b-1844-41de-8a86-7180ead7e141',
    description:
      "This app simplifies personal and group expense management with easy-to-use tools for setting budgets, tracking spending, and monitoring financial goals. Whether you're managing money solo or with others, it offers real-time updates to help you stay on top of your finances and make smart decisions.",
    buttonColor: '#712310',
    bgColor: '#fffaef',
    link: '/portfolio/easy-as-pie-budget',
  },
  {
    id: 'veteye',
    title: 'VetEye',
    mockupLink:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fmockup.webp?alt=media&token=9cccb979-8580-41fe-b451-4a8aa12271be',
    description:
      "The Animal Eye Institute's mobile app enhances veterinary ophthalmology education with a comprehensive library of eye disease images, case studies, and advanced filters. Students can save key posts for easier, more focused study sessions.",
    buttonColor: '#0c8ce8',
    bgColor: '#e7f4fd',
    link: '/portfolio/veteye',
  },
]

class Portfolio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: null,
    }
  }
  render() {
    let title = 'Portfolio',
      description =
        "Explore our portfolio to see how we've transformed ideas into digital success stories."

    const handleMouseEnter = (index) => {
      this.setState({ hover: index })
    }
    const handleMouseLeave = (index) => {
      this.setState({ hover: null })
    }

    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>{title}</h2>
                  {/* <p>{description}</p> */}

                  <div className="service-btn">
                    <a
                      className="btn-transparent rn-btn-dark"
                      href="/portfolio">
                      <span className="text">View More</span>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        style={{ height: 20, width: 20, marginLeft: 10 }}>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            {PortfolioList.map((item, index) => (
              <div
                style={{
                  backgroundColor: item.bgColor,
                }}>
                <div
                  className={`container d-flex flex-column-reverse ${
                    index % 2 == 0 ? 'flex-lg-row' : 'flex-lg-row-reverse'
                  } px-4 px-lg-0 py-5`}>
                  <div
                    className={`col-12 col-lg-7 p-0 d-flex flex-column ${
                      index % 2 == 0 ? '' : 'align-items-lg-end'
                    }`}
                    style={{
                      justifyContent: 'center',
                      // backgroundColor: 'pink',
                    }}>
                    <h3
                      className="portfolio-banner-title fw-bold"
                      style={{ textAlign: 'start', color: item?.buttonColor }}>
                      {item.title}
                    </h3>

                    <p
                      className="portfolio-banner-description "
                      style={{ textAlign: 'start', marginBottom: 40 }}>
                      {item.description}
                    </p>

                    <div style={{ width: '70%' }}>
                      <a href={item.link}>
                        <div
                          className="d-flex align-items-center portfolio-banner-button px-3 py-2"
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                          style={{
                            border: `2px solid ${item.buttonColor}`,
                            backgroundColor: item.buttonColor,
                            // this.state.hover === index
                            //   ? item.buttonColor
                            //   : 'transparent',
                            color: 'white',
                            // this.state.hover === index
                            //   ? 'white'
                            //   : item.buttonColor,
                          }}>
                          View Case Study
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            style={{ height: 20, width: 20, marginLeft: 10 }}>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div
                    style={
                      {
                        // backgroundColor: 'pink',
                      }
                    }>
                    <img
                      src={item?.mockupLink}
                      alt="mockup"
                      class="img-fluid"
                      // style={{ backgroundColor: 'pink' }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default Portfolio
