import React, { Component } from 'react'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { BsGlobe2 } from 'react-icons/bs'
import { GrInstagram } from 'react-icons/gr'
import { FiChevronUp } from 'react-icons/fi'
import ScrollToTop from 'react-scroll-up'
import ModalVideo from 'react-modal-video'
import { useParams } from 'react-router-dom'

import Header from '../component/header/Header'
import FooterTwo from '../component/footer/FooterTwo'
import PageHelmet from '../component/common/Helmet'
import portfolio_list from '../configs/portfolio_list'
import appleStore from '../assets/images/portfolio/appstore.webp'
import playStore from '../assets/images/portfolio/playstore.webp'

const SocialShare = [
  { Social: <GrInstagram />, link: 'https://www.instagram.com/thegreenfelts/' },
  {
    Social: <FaLinkedinIn />,
    link: 'https://www.linkedin.com/company/the-green-felts/',
  },
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/TheGreenFelts/' },
  { Social: <BsGlobe2 />, link: 'https://thegreenfelts.com/' },
]

const topBanner =
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F1.webp?alt=media&token=0a48013a-04fb-4b36-975b-fde09f76c553'

const images = [
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F2.webp?alt=media&token=d58f5e5a-79d0-429e-94c5-fe699c3e7beb',
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F3.webp?alt=media&token=ca4c052d-2e9c-4c13-89e0-7a7bc2a78440',
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F44.webp?alt=media&token=cf7db67b-5150-4a39-a166-3032d5a9d884',
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F55.webp?alt=media&token=629a6a0f-fdf6-47ac-823e-f87e84e97fad',
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F66.webp?alt=media&token=32e9baa3-0a50-4853-9b57-b23b743b36b0',
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F77.webp?alt=media&token=85e542b9-25ff-4592-9d41-90aa9bc3067a',
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F88.webp?alt=media&token=77b55f8b-a028-4802-9418-8f75e327c3c0',
]

const relatedImg1 =
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2FMask%20group.webp?alt=media&token=8b6bce97-d40c-49a9-922c-0d17d501600d'
const relatedImg2 =
  'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2FMask%20group.webp?alt=media&token=8b6bce97-d40c-49a9-922c-0d17d501600d'

const PortfolioDetails = () => {
  const { id } = useParams()
  // const [isOpen, setIsOpen] = React.useState(false)

  // const openModal = () => {
  //   setIsOpen(true)
  // }

  const data = portfolio_list[id]

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Portfolio Details" />

      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <img
        src={data?.top_banner}
        style={{
          width: '100vw',
          backgroundColor: '#CCCCCC',
          aspectRatio: '16/7.23',
        }}
      />
      {/* <div
        className="rn-page-title-area pt--120 pb--190 bg_image "
        data-black-overlay="3"
        style={{ backgroundImage: `url(${topBanner})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient"> </h2>
                <p> </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Breadcrump Area */}

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="portfolio-detail-container">
          <div className="row">
            <div className="col-lg-12 ps-0">
              <div className="portfolio-details">
                <div className="inner">
                  <h2>{data?.name}</h2>
                  {data?.descriptions.map((desc, i) => (
                    <p>{desc}</p>
                  ))}

                  <div className="portfolio-view-list d-flex flex-wrap">
                    {data?.bullet_points.map((point, i) => (
                      <div className="port-view">
                        <span>{point?.title}</span>
                        <h4>{point?.value}</h4>
                      </div>
                    ))}
                  </div>

                  <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                    <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                      {data?.social_links?.map((val, i) => (
                        <li key={i}>
                          <a href={`${val.link}`}>{val.Social}</a>
                        </li>
                      ))}
                    </ul>

                    {(data.apple_store_link || data.play_store_link) && (
                      <div className="mt-3 d-flex justify-content-center justify-content-md-start">
                        {data.apple_store_link && (
                          <img
                            src={appleStore}
                            style={{ width: '150px' }}
                            className="store-button"
                            onClick={() => window.open(data.apple_store_link)}
                          />
                        )}
                        {data.play_store_link && (
                          <img
                            src={playStore}
                            style={{ width: '150px' }}
                            className="store-button"
                            onClick={() => window.open(data.play_store_link)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="portfolio-thumb-inner">
                  {data?.images?.map((img, i) => (
                    <img
                      src={img}
                      alt="Portfolio Images"
                      className="portfolio-image"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Related Work */}
      <div className="portfolio-related-work pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="theme-color font--18 fontWeight600">
                  Related Work
                </span>
                <h2>Our More Projects</h2>
              </div>
            </div>
          </div>

          <div className="row mt--10">
            {data?.related_projects?.map((project, i) => (
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href={`/portfolio/${project?.id}`}>
                      <img src={project.image} alt="Portfolio-images" />
                    </a>
                  </div>

                  {/* <div className="inner">
                    <h4>
                      <a href={`/portfolio-details/${project?.id}`}>
                        {project.title}
                      </a>
                    </h4>
                    <span className="category">{project.descriptions}</span>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Related Work */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  )
}

export default PortfolioDetails
