import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Glenn from '../assets/images/client/Glenn.png'
import Derrick from '../assets/images/client/Derrick.jpeg'
import Mike from '../assets/images/client/Mike.png'

class Testimonial extends Component {
  constructor(props) {
    super(props)
    this.state = { activeTab: 0 }
  }

  render() {
    let title = 'Testimonials',
      description =
        'Hear what our clients have to say about their experience working with INFILP.',
      testimonials = [
        {
          name: 'Glenn Doylr',
          role: 'CEO @ The Green Felts',
          image: Glenn,
          title: 'The Green Felts',
          testimonial:
            "As Co-Owner of The Green Felts, we needed an app for advertising events, managing our player database, enabling event buy-ins, and providing a live leaderboard. INFILP quickly learned our requirements and delivered an excellent app despite their initial unfamiliarity with golf. Their team was a pleasure to work with, consistently updating and improving the app. Now, it's used by various organizations for its ease of use and user-friendly design. I plan to work with INFILP on future projects as well!",
        },
        {
          name: 'Derrick Nissley',
          role: 'Founder @ Easy As Pie Budget, LLC',
          title: 'Easy As Pie Budget',
          image: Derrick,
          testimonial:
            "INFILP is incredible at what they do. They took over my app, Easy As Pie Budget, midway through its development after previous developers left the project. For the past three years, INFILP has managed and improved the app with strong project management skills, excellent communication, dedication, and attention to detail. They have exceeded my expectations and contributed ideas that significantly enhanced the app. I highly recommend INFILP for all your project management and app development needs; you won't be disappointed.",
        },
        {
          name: 'Mike Künnecke',
          role: 'Founder @ Dressplaner GmbH',
          image: Mike,
          title: 'DRESSPLANER GmbH',
          testimonial:
            'We needed a reliable development team for our company administration, and INFILP exceeded our expectations. After a successful initial project, we entrusted them with redeveloping our app, web app, and web presence. INFILP’s deep understanding, insightful suggestions, and round-the-clock availability have greatly enhanced our projects. Their excellent communication and project management skills ensure our needs are fully met. We highly recommend INFILP and look forward to many more years of collaboration.',
        },
      ]

    let handleTabClick = (index) => {
      this.setState({ activeTab: index })
    }

    console.log(this.state.activeTab)

    return (
      <React.Fragment>
        <div style={{ marginBottom: 50 }} className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row container-lg">
          <div className="col-12 col-lg-4 testimonial-user-container">
            {testimonials.map((testimonial, index) => (
              <div
                className={'testimonial-user-card p-3 mt-2'}
                style={{
                  backgroundColor:
                    this.state.activeTab === index
                      ? 'rgba(0, 0, 0, 0.06)'
                      : 'transparent',
                }}
                onClick={() => handleTabClick(index)}>
                <div className="d-flex align-items-center">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                    }}
                  />
                  <p className="name ml-2">{testimonial.name}</p>
                </div>
                <p className="role mt-2">{testimonial.role}</p>
              </div>
            ))}

            <div className="mt-4 mb-4 ml-3">
              <div className="service-btn">
                <a className="btn-transparent rn-btn-dark">
                  <span className="text">View More</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    style={{ height: 20, width: 20, marginLeft: 10 }}>
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="mt-3 mt-lg-0 ml-lg-4 col-12 col-lg-8 ">
            <svg
              className="mb-3"
              width="24"
              height="27"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <h3>{testimonials[this.state.activeTab].title}</h3>
            <p>{testimonials[this.state.activeTab].testimonial}</p>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner container">
                    <p>
                      As Co-Owner of The Green Felts, we needed an app for
                      advertising events, managing our player database, enabling
                      event buy-ins, and providing a live leaderboard. INFILP
                      quickly learned our requirements and delivered an
                      excellent app despite their initial unfamiliarity with
                      golf. Their team was a pleasure to work with, consistently
                      updating and improving the app. Now, it's used by various
                      organizations for its ease of use and user-friendly
                      design. I plan to work with INFILP on future projects as
                      well!
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Glenn Doylr </span> - CEO @ The Green Felts
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner container">
                    <p>
                      INFILP is incredible at what they do. They took over my
                      app, Easy As Pie Budget, midway through its development
                      after previous developers left the project. For the past
                      three years, INFILP has managed and improved the app with
                      strong project management skills, excellent communication,
                      dedication, and attention to detail. They have exceeded my
                      expectations and contributed ideas that significantly
                      enhanced the app. I highly recommend INFILP for all your
                      project management and app development needs; you won't be
                      disappointed.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span> Derrick Nissley </span> - Founder @ Easy As Pie
                      Budget, LLC
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner container">
                    <p>
                      {
                        'We needed a reliable development team for our company administration, and INFILP exceeded our expectations. After a successful initial project, we entrusted them with redeveloping our app, web app, and web presence. INFILP’s deep understanding, insightful suggestions, and round-the-clock availability have greatly enhanced our projects. Their excellent communication and project management skills ensure our needs are fully met. We highly recommend INFILP and look forward to many more years of collaboration.'
                      }
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Mike Künnecke </span> - Founder @ Dressplaner GmbH
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={Glenn} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>

                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={Derrick} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>

                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={Mike} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
              </TabList>
            </Tabs>
          </div>
        </div> */}
      </React.Fragment>
    )
  }
}
export default Testimonial
