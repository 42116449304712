import React, { Component } from 'react'
import PageHelmet from '../component/common/Helmet'
import Breadcrumb from '../elements/common/Breadcrumb'
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
} from 'react-icons/fi'
import ScrollToTop from 'react-scroll-up'

import { TbDeviceMobileCode } from 'react-icons/tb'
import { PiFigmaLogoThin, PiAppStoreLogoLight } from 'react-icons/pi'
import { VscRobot } from 'react-icons/vsc'
import { PiOpenAiLogoThin } from 'react-icons/pi'

import Header from '../component/header/Header'
import Footer from '../component/footer/Footer'
import FooterTwo from '../component/footer/FooterTwo'

const development = [
  {
    icon: <TbDeviceMobileCode />,
    title: 'MOBILE APP DEVELOPMENT',
    description:
      'In a world where everyone has an app, we make yours stand out. Be it aesthetic UIs or robust backends, our mobile app development services have you covered.',
  },
  {
    icon: <FiMonitor />,
    title: 'WEB DEVELOPMENT',
    description:
      'Anybody can build you a website. We build for scale and reliability. Our web development services future-proof your product from Day one.',
  },
]

const ai = [
  {
    icon: <PiOpenAiLogoThin />,
    title: 'GENERATIVE AI',
    description:
      "Having trouble keeping up with how fast AI is changing our world? This is where we come in. Let's figure out how to get GenAI to work for you.",
  },
]

const designing = [
  {
    icon: <PiFigmaLogoThin />,
    title: 'UI/UX Design',
    description:
      'Design isn’t just how it looks, it’s how it works. We craft intuitive interfaces that feel natural to users, ensuring your product not only looks good but is a pleasure to use.',
  },
]

const ServiceList = [
  {
    icon: <FiCast />,
    title: 'Business Stratagy',
    description:
      'I throw myself down among the tall grass by the stream as I lie close to the earth.',
  },
  {
    icon: <FiLayers />,
    title: 'Website Development',
    description:
      'I throw myself down among the tall grass by the stream as I lie close to the earth.',
  },
  {
    icon: <FiUsers />,
    title: 'Marketing & Reporting',
    description:
      'I throw myself down among the tall grass by the stream as I lie close to the earth.',
  },
  {
    icon: <FiMonitor />,
    title: 'Mobile App Development',
    description:
      'I throw myself down among the tall grass by the stream as I lie close to the earth.',
  },
  {
    icon: <FiCast />,
    title: 'Website Development',
    description:
      'I throw myself down among the tall grass by the stream as I lie close to the earth.',
  },
  {
    icon: <FiMonitor />,
    title: 'Marketing & Reporting',
    description:
      'I throw myself down among the tall grass by the stream as I lie close to the earth.',
  },
]
class Service extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Service" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <Breadcrumb
          title={'Our Services'}
          backgroundImage={'bg_image--13'}
          overlay={'4'}
        />
        {/* End Breadcrump Area */}

        {/* Start DEVELOPMENT Service Area */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Development</h2>
                  <p>Tailored apps that perfectly fit your unique needs.</p>
                </div>
              </div>
            </div>
            <div className="row service-one-wrapper">
              {development.map((val, i) => (
                <div
                  className="col-xl-6 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}>
                  <a
                  // href="/service-details"
                  >
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start GENERATIVE AI Service Area */}
        <div className="service-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Artificial Intelligence</h2>
                  <p>Harness AI to revolutionize your business processes.</p>
                </div>
              </div>
            </div>
            <div className="row service-one-wrapper">
              {ai.map((val, i) => (
                <div
                  className="col-xl-6 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}>
                  <a
                  // href="/service-details"
                  >
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start DESIGNER Service Area */}
        <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Designing</h2>
                  <p>
                    Intuitive, user-centric designs that enhance every
                    interaction.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="row creative-service"> */}
            <div className="row service-one-wrapper">
              {designing.map((val, i) => (
                <div
                  className="col-xl-6 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}>
                  <a
                  // href="/service-details"
                  >
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Service Area */}
        {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Development</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}>
                  <a className="text-center" href="/service-details">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    )
  }
}
export default Service
