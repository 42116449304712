import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { FiCheck } from 'react-icons/fi'
import CounterTwo from '../counters/CounterTwo'

class TabsOne extends Component {
  render() {
    let tab1 = 'Why INFILP?',
      tab2 = 'Our mission',
      tab3 = 'Friendly Support'

    const { tabStyle } = this.props
    var namesItemOne = [
      'No overpromising; we value honesty.',
      'Obsessed with every detail.',
      'Quality-focused, not budget-based.',
      'Realistic goals and honest timelines.',
    ]

    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  {/* <TabList className={`${tabStyle}`}> */}
                  {/* <Tab>{tab1}</Tab> */}
                  {/* <Tab>{tab2}</Tab> */}
                  {/* <Tab>{tab3}</Tab> */}
                  {/* </TabList> */}

                  <TabPanel>
                    <div className="single-tab-content">
                      {/* <p>
                        Choosing us means partnering with a dedicated team of
                        experts committed to crafting custom tech solutions
                        using the best architecture. We are a reliable team with
                        a proven history of not only developing but also
                        maintaining software for long-term support. Our
                        extensive experience ensures your projects are in
                        capable hands, reflected in our impressive track record
                      </p> */}

                      <div style={{ marginTop: -20 }}>
                        <h4>HERE’S WHAT YOU SHOULD KNOW</h4>
                        <h6>
                          We believe the outcome of every project comes down to
                          how well we fit together.
                        </h6>

                        <ul className="list-style--1">
                          {namesItemOne.map((name, index) => {
                            return (
                              <li key={index}>
                                <FiCheck /> {name}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <p>
                        Nipa ipsum dolor sit amet consectetur adipisicing elit.
                        Neque ab aliquid, atque enim, vero nobis quam beatae
                        nesciunt aliquam molestias, optio hic laborum esse.
                        Deserunt architecto officiis laudantium corporis
                        voluptatem.
                      </p>
                      {/* <p>
                        Nipa ipsum dolor sit amet consectetur adipisicing elit.
                        Neque ab aliquid, atque enim, vero nobis quam beatae
                        nesciunt aliquam molestias, optio hic laborum esse.
                        Deserunt architecto officiis laudantium corporis
                        voluptatem.
                      </p>
                      <p>
                        Nipa ipsum dolor sit amet consectetur adipisicing elit.
                        Neque ab aliquid, atque enim, vero nobis quam beatae
                        nesciunt aliquam molestias, optio hic laborum esse.
                        Deserunt architecto officiis laudantium corporis
                        voluptatem.
                      </p> */}
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <p>
                        Popy ipsum dolor sit amet consectetur adipisicing elit.
                        Neque ab aliquid, atque enim, vero nobis quam beatae
                        nesciunt aliquam molestias, optio hic laborum esse.
                        Deserunt architecto officiis laudantium corporis
                        voluptatem.
                      </p>
                      {/* <p>
                        Popy ipsum dolor sit amet consectetur adipisicing elit.
                        Neque ab aliquid, atque enim, vero nobis quam beatae
                        nesciunt aliquam molestias, optio hic laborum esse.
                        Deserunt architecto officiis laudantium corporis
                        voluptatem.
                      </p>
                      <p>
                        Popy ipsum dolor sit amet consectetur adipisicing elit.
                        Neque ab aliquid, atque enim, vero nobis quam beatae
                        nesciunt aliquam molestias, optio hic laborum esse.
                        Deserunt architecto officiis laudantium corporis
                        voluptatem.
                      </p> */}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            <div>
              <CounterTwo />
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    )
  }
}

export default TabsOne
